
























import Vue from 'vue';
import Component, {mixins} from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';
import {Prop} from 'vue-property-decorator';
import {placeModule} from '@/store';

@Component
export default class ReviewList extends mixins(DataFormat) {
  @Prop({
    required: true
  })
  public review!: number;
}
