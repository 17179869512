

































































import Vue from 'vue';
import Component, {mixins} from 'vue-class-component';
import ThumbnailList from '@/components/list/ThumbnailList.vue';
import MainInfo from '@/components/deposit/MainInfo.vue';
import dataFormat from '@/shared/utils/dataFormat';
import ReviewList from '@/components/list/ReviewList.vue';
import ReserveChange from '@/components/popup/ReserveChange.vue';
import ReserveCancel from '@/components/popup/ReserveCancel.vue';
import PaymentInfo from '@/components/deposit/PaymentInfo.vue';
import {paymentModule, placeModule} from '@/store';
import MainFooter from '@/components/layout/MainFooter.vue';
import { Prop } from 'vue-property-decorator';

@Component({ components: { ThumbnailList, MainInfo, ReviewList, ReserveChange, ReserveCancel, PaymentInfo, MainFooter } })
export default class Result extends mixins(dataFormat) {
  public placeId: string = '';

  @Prop()
  public selectLang!: any;

  public beforeRouteEnter(to, from, next) {
    next((vm) => {
      document.querySelector('html' as any).classList.remove('open-modal');
      paymentModule.fetchReservationSetting(to.params.reservationId).then((res) => {
        if (res.code === 20000) {
          // 결제가 안됐을 경우에 다시 결제페이지로 이동
          if (
            res.data.pay_status === 'wait' &&
            res.data.reserv_status !== 'canceled'
          ) {
            vm.$router.push({name: 'DepositMain', params: { reservationId: to.params.reservationId }});
            return false;
          }
          vm.placeId = res.data.restaurant.poing_place_id;
          vm.initReview(res.data.restaurant.poing_place_id);
          // 매장 정보 조회
          placeModule.fetchPlaceInfoData(res.data.restaurant.poing_place_id);
          // 매장 이미지 조회
          placeModule.fetchPlaceImage(res.data.restaurant.poing_place_id);
        }
      });
      paymentModule.fetchPayTypes(to.params.reservationId);
    })
  }

  public initReview(id) {
    const params = {
      type: 'realtime',
      offset: 1,
      limit: 3,
      place_id: id,
      time: this.$moment().format('YYYY-MM-DD HH:mm:ss')
    }
    placeModule.fetchPlaceReview(params);
  }

  public reservCancelModal() {
    document.querySelector('html' as any).classList.add('open-modal');
    this.$modal.show('reserveCancel');
  }

  get review() {
    return placeModule.placeReviewData;
  }

  get placeInfoData() {
    return placeModule.placeInfoData;
  }

  get placeImage() {
    return placeModule.placeImage;
  }

  get reservation(): any {
    return paymentModule.getReservationData;
  }

  public copyNoti() {
    this.$q.notify({
      message: '예약정보가 복사되었습니다. <br/>공유가 필요한 곳에 붙여넣기를 해주세요.',
      html: true
    });
  }

  get shareText() {
    const data = this.reservation ? this.reservation : '';
    const text =
      `[${data.restaurant.name}]\n예약정보 안내입니다.\n\n인원: ${data.party_size}명\n일정: ${this.reservationDate(data.reserv_start, 'time')}\n주소: ${this.placeInfoData && this.placeInfoData.address}\n\n${location.protocol}//${location.host}/result/${this.$route.params.reservationId}?share=true`;
    return text;
  }

  // 예약 취소 버튼 노출
  get reserveCancelValidation() {
    const now = this.$moment();
    const startDate = this.$moment(new Date(this.reservation.reserv_start));

    if (this.status !== 'canceled' && this.status !== 'visited' && this.status !== 'share') {
      if (this.reservation && this.reservation.deposit_policy.cancel_able) {
        if (now.isBefore(startDate)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // 현재 예약 상태
  get status() {
    if (this.reservation) {
      if (this.$route.query.share) {
        return 'share'
      } else if (this.reservation.reserv_status === 'change_wait_confirm') {
        return 'wait';
      } else if (this.reservation.reserv_status === 'canceled' || this.reservation.reserv_visit_status === 'noshow') {
        return 'canceled';
      } else if (
        this.reservation.reserv_visit_status === 'appeared' ||
        this.reservation.reserv_visit_status === 'partial_appeared'
      ) {
        return 'visited';
      } else {
        return 'confirmed';
      }
    }
  }

  // 예약 상태별 상단바 문구
  get statusText() {
    if (this.reservation) {
      if (this.$route.query.share) {
        return '예약이 확정되었습니다.';
      } else if (this.reservation.reserv_status === 'change_wait_confirm') {
        return '변경이 요청되었습니다.';
      } else if (this.reservation.reserv_status === 'canceled') {
        return `예약이 취소되었습니다.<br/>결제기한이 경과되었거나, 예약이 취소되어 결제가 취소되었습니다.`;
      } else if (this.reservation.reserv_visit_status === 'noshow') {
        return '노쇼 처리되었습니다.';
      } else if (this.reservation.reserv_visit_status === 'appeared' || this.reservation.reserv_visit_status === 'partial_appeared') {
        if (this.reservation.deposit[0].refund_type === 'difference') {
          return '방문 처리되었습니다.';
        }
        return '방문 처리되었습니다.<br/> 결제수단으로 보증금이 환불 될 예정입니다.';
      } else {
        return '예약이 확정되었습니다.';
      }
    }
  }

  // 인원 성인만 있는지 체크
  get onlyAdultCheck() {
    const re = this.reservation.people_types.filter((row: any, index: any) => {
      return index !== 0 && Number(row.party_size) !== 0;
    });
    if (re.length) {
      return true;
    }
    return false;
  }
  // 마지막 인원 타입
  get lastPersonKindIndex() {
    let type = '';
    this.reservation.people_types.filter((row: any) => {
      if (row.party_size !== 0) {
        type = row.name;
      }
    });
    if (type !== '') {
      return this.reservation.people_types.findIndex((row: any) => {
        return row.name === type;
      })
    }
    return 0;
  }
}
