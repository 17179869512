































































import Vue from 'vue';
import Component, {mixins} from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';
import {Prop, Watch} from 'vue-property-decorator';
import {paymentModule} from '@/store';
import moment from 'moment';

@Component
export default class ReservationChange extends mixins(DataFormat) {
  public $refs!: {
    calendar: any;
  };
  @Prop({
    required: true
  })
  public reservation!: any;

  public selectedDate: any = new Date(this.reservationDate(this.reservation.reserv_start, 'noTime'));
  public selectedTime: any = null;
  public requestText: string = '';
  public reserveId: string = this.$route.params.reservationId;
  public height: number = 120;
  public isLoading: boolean = true;
  public isFirst: boolean = true;
  public btnLoading: boolean = false;

  public openPopup() {
    document.querySelector('html' as any).classList.add('open-modal');
    this.initData();
    const nextMonth:any = this.$moment(new Date(this.reservation.reserv_start)).add(1, 'month');
    const payload = {
      reservation_id: this.reserveId,
      date: this.reservationDate(this.reservation.reserv_start, 'simpleM')
    };
    const nextPayload = {
      reservation_id: this.reserveId,
      date: this.reservationDate(nextMonth, 'simpleM')
    }
    paymentModule.fetchReservePossibleDate(payload);
    paymentModule.fetchReservePossibleNextDate(nextPayload);
  }
  public closePopup() {
    document.querySelector('html' as any).classList.remove('open-modal');
  }

  // 초기 데이터 세팅 및 초기화
  public initData() {
    const reservStart = this.reservation.reserv_start.split('T');
    this.selectedDate = new Date(this.reservationDate(this.reservation.reserv_start, 'noTime'));
    this.selectedTime = this.formatTime(reservStart[1]);
    this.$refs.calendar.move(this.selectedDate);
    if (this.reservation.memo_customer) {
      this.requestText = this.reservation.memo_customer;
    }
  }

  // 요청사항 텍스트 박스 높이
  public inputHeight() {
    const textarea = document.querySelector('.text-area' as any);
    textarea.addEventListener('input', () => {
      this.height = textarea.scrollHeight;
    });
  }

  // 예약 변경 api 호출
  public updateReservation() {
    this.btnLoading = true;
    const date: any = this.$moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const params = {
      reservation_id: this.$route.params.reservationId,
      date: date + ' ' +  this.selectedTime + ':00',
      memo: this.requestText
    };
    paymentModule.updateReservation(params).then((res) => {
      if (res.code === 20000) {
        paymentModule.fetchReservationSetting(this.$route.params.reservationId).then(() => {
          document.querySelector('html' as any).classList.remove('open-modal');
          this.$modal.hide('reserveChange');
        });
      } else {
        this.$q.notify(res.message);
      }
      this.btnLoading = false;
    });
  }

  @Watch('selectedDate')
  public updatePossibleTime() {
    this.isLoading = true;

    // 처음 팝업 진입시 seletedTime값을 초기화 안되게 하기 위해서 추가
    if (this.isFirst) {
      this.isFirst = false;
    } else {
      this.selectedTime = '';
    }

    const payload = {
      reservation_id: this.reserveId,
      date: this.reservationDate(this.selectedDate, 'noTime')
    };
    paymentModule.fetchReservePossibleTime(payload).then(() => {
      if (this.reservationPossibleTime) {
        const re = this.reservationPossibleTime.filter((row: any) => (this.selectedTime === row.time) && !row.active);
        if (re.length) {
          this.selectedTime = '';
        }
      }
      this.isLoading = false;
    });
  }

  // 예약 가능한 날짜 조회
  get reservationPossibleDate(): any {
    return paymentModule.getReservationDate;
  }

  // 예약 가능한 다음달 날짜 조회
  get reservationPossibleNextDate(): any {
    return paymentModule.getReservationNextDate;
  }

  // 예약 가능한 시간 조회
  get reservationPossibleTime(): any {
    return paymentModule.getReservationTime;
  }

  // 요청사항 최대글자(한글/영문)
  get maxLength() {
    const last = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    const re = last.test(this.requestText.substr(999, 1));
    if (re) {
      return 999;
    }
    return 1000;
  }

  // 달력 최소, 최대 날짜
  get calendarLimit() {
    return {
      minDate: moment(this.reservation.reserv_start).toDate(),
      maxDate: moment(this.reservation.reserv_start).add(1, 'M').endOf('month').toDate()
    }
  }

  // 예약 불가능한 날짜 조회
  get offDays() {
    const days: any = [];
    // 이번달 불가능한 날짜 추가
    if (this.reservationPossibleDate) {
      for (const list of this.reservationPossibleDate) {
        if (!list.active) {
          const v = new Date(list.date);
          days.push(v);
        }
      }
    }
    // 다음달 불가능한 날짜 추가
    if (this.reservationPossibleNextDate) {
      for (const list of this.reservationPossibleNextDate) {
        if (!list.active) {
          const v = new Date(list.date);
          days.push(v);
        }
      }
    }
    return days;
  }
  get changeDisable() {
    if (!this.selectedDate || !this.selectedTime) {
      return true;
    }
    return false;
  }
}
